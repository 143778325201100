import { Helmet } from "react-helmet";
import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import Footer from "./Footer";
import Header from "./Header";
import ScrollProgressBar  from './ScrollProgressBar'

function BaseLayout(props) {
  const  {rpdata}  = useContext(GlobalDataContext);
  return (
    <>
      <Helmet>
        <title>
          {props.PageName} | {`${rpdata?.dbPrincipal?.name}`}
        </title>
        <meta
          name="description"
          content={`${rpdata?.dbAbout?.[0].text.substring(0, 150) + "..."}`}
        />
        <meta
          name={` ${rpdata?.dbPrincipal?.name}`}
          content={` ${rpdata?.dbPrincipal?.name}`}
        ></meta>
        <meta name="robots" content="index,follow" />
        <meta name="googlebot" content="index,follow" />
        <link rel="icon" href={rpdata?.dbPrincipal?.favicon} />
      </Helmet>
      <Header />
      <ScrollProgressBar />

      <main>{props.children}</main>
      <div className="visor_Counter z-50">
        <div className="elfsight-app-b8a56f1e-13f0-420d-a542-091d4e218b2d"></div>
      </div>
      <Footer />
      <div className="elfsight-app-46270ed3-c022-41b5-bf1e-7148966555d0"></div>
    </>
  );
}

export default BaseLayout;
